import React, { useState, useEffect } from 'react';
import { IoSearch, IoLocation } from "react-icons/io5";
import Select from 'react-select';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';  
import debounce from 'lodash/debounce';  
import { useLanguage } from './LanguageContext.js';
import utils from '../../../utils.js'
import { useAuthUser } from 'react-auth-kit';

const SearchBar = ({
}) => {
  const { language,langStrings} = useLanguage();
  const authUser = useAuthUser();
  const ST = langStrings.HOMEPAGE;
  const defaultSpecialtyOptions = [
    { value: 'Gynaecologist', label: 'Gynaecologist' },
    { value: 'General Practitioner', label: 'General Practitioner' },
    { value: 'Orthopaedist', label: 'Orthopaedist' },
    { value: 'Paediatrician', label: 'Paediatrician' },
    { value: 'Nephrologist', label: 'Nephrologist' },
    { value: 'Cardiologist', label: 'Cardiologist' },
    { value: 'Neurologist', label: 'Neurologist' },
    { value: 'Neurosurgeon', label: 'Neurosurgeon' },
  ]
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [specialtyOptions, setSpecialtyOptions] = useState(defaultSpecialtyOptions);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectType,setSelectedType] = useState('');
  const [urlNikalo, UrlSetKaro] = useState(''); 
  const [specialtyInputValue, setSpecialtyInputValue] = useState(''); 
  

  const navigate = useNavigate();
  const location = useLocation(); 
  let SUGGEST_URL = process.env.REACT_APP_JAVA_BASE_URL+process.env.REACT_APP_SUGGEST_URL;
  
  const fetchDefaultSpecialtyOptions = async () => {
      try {
        const LanguageLocale = language == 'English' ? 'ENGLISH':'GERMAN';
        const token = authUser()?.authToken ||  ''
        const response = await axios.get(`${SUGGEST_URL}`, {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
        });
        const data = response.data && response.data.data ? response.data.data : [];
        const options = data.map(item => ({
          value: item.value,
          label: item.title,
          url: item.url,
        }));
        setSpecialtyOptions(options);
      } catch (error) {
        console.error('Error fetching default specialty options:', error);
        setSpecialtyOptions(defaultSpecialtyOptions);
      }
  };
  useEffect(()=>{
    fetchDefaultSpecialtyOptions();
  }, []);
  const fetchSpecialtyOptions = debounce((inputValue) => {
    if (inputValue) {
      const LanguageLocale = language == 'English' ? 'ENGLISH':'GERMAN';
      const token = authUser()?.authToken ||  ''
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=searchkey`,{
        headers:{
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        }
      })
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];   
          if (data.length > 0) {
            const options = data.map(item => ({
              value: item.value,  
              label: item.title,
              url: item.url
            }));
            setSpecialtyOptions(options);
          } else {
            setSpecialtyOptions(defaultSpecialtyOptions);
          }
        })
        .catch(error => {
          console.error('Error fetching specialty options:', error);
          setSpecialtyOptions(defaultSpecialtyOptions); 
        });
    } else {
      fetchDefaultSpecialtyOptions();
    }
  }, 300);

const fetchLocations = async () => {
    try {
      const LanguageLocale = language == 'English' ? 'ENGLISH':'GERMAN';
      const url = process.env.REACT_APP_JAVA_BASE_URL +  process.env.REACT_APP_LOCATION + process.env.REACT_APP_CITY;
      const token = authUser()?.authToken ||  ''
      const response = await axios.get(url, {
        headers: {
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
      });
      const locationData = response.data.map(city => ({
        value: utils.getLanguageString(language,city.name, city.nameDe,),
        label: utils.getLanguageString(language,city.name, city.nameDe),
        id: city.id,
        state: city.stateId,
        originalName: city.name,
        originalNameDe: city.nameDe
      }));
      setLocationOptions(locationData);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setLocationOptions([
        { 
          value: utils.getLanguageString(language,'Vienna', 'Wien'),
          label: utils.getLanguageString(language,'Vienna', 'Wien'),
          originalName: 'Vienna',
          originalNameDe: 'Wien'
        },
        { 
          value: utils.getLanguageString(language,'Klagenfurt', 'Klagenfurt'),
          label: utils.getLanguageString(language,'Klagenfurt', 'Klagenfurt'),
          originalName: 'Klagenfurt',
          originalNameDe: 'Klagenfurt'
        },
        { 
          value: utils.getLanguageString(language,'Wiener Neustadt', 'Wiener Neustadt'),
          label: utils.getLanguageString(language,'Wiener Neustadt', 'Wiener Neustadt'),
          originalName: 'Wiener Neustadt',
          originalNameDe: 'Wiener Neustadt'
        }
      ]);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [language]);

 
  let Myurl = '/new-search?';
  const leJaoFiltersPar = (e) => {
    e.preventDefault(); 
    const specialty = selectedSpecialty ? selectedSpecialty.value : '';
    const location = selectedLocation ? selectedLocation.value : '';
    const url = urlNikalo;
    Myurl += `searchkey=${encodeURIComponent(specialty)}`
    Myurl += `&locationkey=${encodeURIComponent(location)}`;
    if(url){
      Myurl +=  `&${url}`;
    }
    navigate(Myurl);
  }
  const DropdownIndicator = () => null;
  return (
    <div className="container ">
      <div className="row justify-content-center">
        <form className="row " method="get" style={{ width: '100%',paddingTop:'0px' }}>
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoSearch style={{ zIndex: '99', position: 'absolute', left: '14px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <Select
              isClearable={true}
              components={{ DropdownIndicator }} 
              noOptionsMessage={() => null}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '60px', 
                  border: '1px solid black',
                  borderRadius: '0px',
                  width: '100%',
                  fontSize:  window.innerWidth<430? '19px':'20px',
                  paddingLeft: '40px',
                  fontWeight: 'bold'
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '20px',
                  zIndex: 99,

                }),
                menu: (base) => ({
                  ...base,
                  opacity: 1,
                  backgroundColor: 'white',
                  color: 'black',
                  zIndex: 99,
                }),
                option: (base, state) => ({
                  ...base,
                  opacity: 1,
                  zIndex: 99,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
              onInputChange={(newValue) => {
                setSpecialtyInputValue(newValue); 
                fetchSpecialtyOptions(newValue); 
              }}
              onChange={(option) => {
                console.log(`sir ji `,option)
                setSelectedSpecialty(option);
                setSpecialtyInputValue(option ? option.label : '');
                setSelectedType(option ? option.type : '');
                UrlSetKaro(option ? option.url : '');
              }}
              options={specialtyOptions}  
              placeholder={ST.SEARCH_SECTION.INPUT.label3}
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoLocation style={{ zIndex: '1', position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <Select
              components={{ DropdownIndicator }} 
              noOptionsMessage={() => null}
              isClearable={true}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '60px',
                  border: '1px solid black',
                  borderRadius: '0px',
                  fontSize: '20px',
                  width: '100%',
                  paddingLeft: '40px',
                  fontWeight: 'bold',
                  opacity: 1,
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '20px',
                  fontWeight: 'bold',
                }),
                menu: (base) => ({
                  ...base,
                  opacity: 1,
                  backgroundColor: 'white',
                  color: 'black',
                }),
                option: (base, state) => ({
                  ...base,
                  opacity: 1,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
              onInputChange={(newValue) => {
                setLocationInputValue(newValue); 
                fetchLocations(newValue);
              }}
              onChange={(option) => {
                setSelectedLocation(option); 
                setLocationInputValue(option ? option.label : '');
              }}
              options={locationOptions}
              placeholder={ST.SEARCH_SECTION.INPUT.label1}
            />
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12 no-gap width-reduce">
            <button 
              onClick={leJaoFiltersPar}
              type="submit" 
              name="text-search-submit-btn" 
              className="btn btn-primary w-100"
              style={{ height: '60px', fontSize: '25px', fontWeight: 'bold', backgroundColor: '#f35b2a', border: 'solid', borderWidth: '1px', borderColor: 'black', borderRadius: '0px', boxShadow:'none' }}
            >
              {ST.SEARCH_SECTION.INPUT.label2}
            </button>
          </div>
        </form>
      </div>
      
      <style jsx>{`
        @media (min-width: 1024px) {
          .no-gap {
            margin: 0 !important;
            padding: 0 !important;
          }
          .custom-shift{
            margin-left:2px;
          }
        }
        
        @media (min-width: 1920px) {
          .custom-shift {
            transform: translateX(-145px);
            
          }
        }
        @media (min-width: 1440px) {
          .custom-shift {
            transform: translateX(65px);
            padding:0px;
            margin-right:0px;
          }
          
          .width-reduce{
            width: 25%;
          }
        }
        @media(max-width: 768px) {
          .try-shift{
          padding-top:0px;
          }
        }
        
          @media (min-width: 1920px) {
          .custom-shift {
            transform: translateX(-133px);
            margin-top:50px;
            margin-bottom:50px;
            margin-right:37px;
          }
        }
      `}</style>
    </div>
  );
};

export default SearchBar;


