import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './Footer.css';
import { useLanguage } from './LanguageContext.js';
import { useSignOut, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';

const Footer = () => {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.HOMEPAGE;
  const AT = langStrings.AreYouDoc.HEADERS;
  const [Mobile, setMobile] = useState(window.innerWidth < 768);

  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const Resize = () => {
      setMobile(window.innerWidth < 720);
    }
    Resize();
    
    window.addEventListener('resize', Resize);
    return () => window.removeEventListener('resize', Resize);
  }, []);

  const safeNavigate = (path, options = {}) => {
    navigate(path, { 
      state: { from: location.pathname },
      replace: false,
      ...options 
    });
  };

  const takeToDocRegister = () => {
    if (isAuthenticated() && authUser()?.isUser && !authUser()?.isDoctor) {
      safeNavigate('/new-doctor-register');
    } else if (!authUser()?.isUser) {
      toast.warning(AT.login, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      safeNavigate('/user-login', { state: { from: 'List Your Practice' } });
    } else if (authUser().isDoctor) {
      toast.info(AT.doctor, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
    }
  };

  const handleLinkClick = (e, path) => {
    e.preventDefault();
    safeNavigate(path);
  };

  return (
    <footer style={{backgroundColor:'#24262b'}} className="footer text-white py-4">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><Link to="" onClick={(e) => handleLinkClick(e,'/')}>{ST.FOOTER_SECTION.LINKS.label}</Link></li>
              <li><Link to="/about-us">{ST.FOOTER_SECTION.LINKS.label1}</Link></li>
              <li><a href={`http://${process.env.REACT_APP_WORDPRESSIP}`} target="_blank" rel="noopener noreferrer">{ST.FOOTER_SECTION.LINKS.label2}</a></li>
              <li><Link to="/contact-us">{ST.FOOTER_SECTION.LINKS.label3}</Link></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><Link to="/terms-of-service">{ST.FOOTER_SECTION.LINKS.label4}</Link></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label5}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label6}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label7}</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><Link to="/cookie-management">{ST.FOOTER_SECTION.LINKS.label8}</Link></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label9}</a></li>
              <li><Link to="/help">{ST.FOOTER_SECTION.LINKS.label10}</Link></li>
              <li><Link to="/impressium">{ST.FOOTER_SECTION.LINKS.label11}</Link></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li>
                <a 
                  href="" 
                  onClick={(e) => {
                    e.preventDefault();
                    takeToDocRegister();
                  }}
                >
                  {ST.FOOTER_SECTION.LINKS.label12}
                </a>
              </li>
              <li><Link to="/marketing">{ST.FOOTER_SECTION.LINKS.label13}</Link></li>
              <li><Link to="/careers">{ST.FOOTER_SECTION.LINKS.label14}</Link></li>
              <li>
                <a 
                  href="" 
                  onClick={(e) => {
                    e.preventDefault();
                    toggleLanguage();
                  }}
                >
                  {ST.FOOTER_SECTION.LINKS.label15}
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        {
          Mobile ?
          <div className="row mt-4 justify-content-center">
            <div className="col-auto justify-content-center">
                <div className="social-links d-flex justify-content-center">
                  <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about" className="text-white"><i className="fab fa-facebook-f"></i></a>
                  <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr" className="text-white"><i className="fab fa-instagram"></i></a>
                  <a href="https://x.com/Arztme" className="text-white"><i className="fab fa-twitter"></i></a>
                  <a href="https://medium.com/@Arztme" className="text-white"><i className="fab fa-medium"></i></a>
                  <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true" className="text-white"><i className="fab fa-linkedin-in"></i></a>
                </div>
                <div className="social-links d-flex justify-content-center">
                  <a href="https://www.youtube.com/@Arztme" className="text-white"><i className="fab fa-youtube"></i></a>
                  <a href="https://www.reddit.com/user/Inevitable-Art-9478/" className="text-white"><i className="fab fa-reddit"></i></a>
                  <a href="https://www.quora.com/profile/Arztme/posts" className="text-white"><i className="fab fa-quora"></i></a>
                  <a href="https://at.pinterest.com/doctorsalzburg" className="text-white"><i className="fab fa-pinterest"></i></a>
                </div>
            </div>
            <div className="col-auto justify-content-center mt-4" style={{fontSize:'14px'}}>
              {ST.FOOTER_SECTION.LINKS.label16}
            </div>
          </div>
          :
          <div className="row mt-4 ">
            <div className="col-12 justify-content-between d-flex align-items-center">
              <div className="col-3 foo-co">
              <ul className="list-unstyled">
                <li><a href="">
                {ST.FOOTER_SECTION.LINKS.label16}</a></li>
              </ul>
            </div>
             <div className="col-auto">
              <div className="social-links d-flex justify-content-center">
                <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about" aria-label="facebook" className="text-white"><i className="fab fa-facebook-f"></i></a>
                <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr" aria-label="instagram" className="text-white"><i className="fab fa-instagram"></i></a>
                <a href="https://x.com/Arztme" className="text-white" aria-label="twitter"><i className="fab fa-twitter"></i></a>
                <a href="https://medium.com/@Arztme" className="text-white" aria-label="medium"><i className="fab fa-medium"></i></a>
                <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true" className="text-white" aria-label="linkedin"><i className="fab fa-linkedin-in"></i></a>
                <a href="https://www.youtube.com/@Arztme" className="text-white" aria-label="youtube"><i className="fab fa-youtube"></i></a>
                <a href="https://www.reddit.com/user/Inevitable-Art-9478/" className="text-white" aria-label="redddit"><i className="fab fa-reddit"></i></a>
                <a href="https://www.quora.com/profile/Arztme/posts" className="text-white" aria-label="quora"><i className="fab fa-quora"></i></a>
                <a href="https://at.pinterest.com/doctorsalzburg" className="text-white" aria-label="pinterest"><i className="fab fa-pinterest"></i></a>
              </div>
             </div>
             <div className="col-3"></div>
            </div>
          </div>
        }
      </div>
    </footer>
  );
  
};

export default Footer;