import { MDBInput } from 'mdb-react-ui-kit';
import './DoctorRegisterViewNew.css';
import { strings } from '../../../strings/index.js'
import MultiSelectCompo2 from './MultiSelectCompo2.jsx';
import SingleSelectComponent from './SingleSelectComponent.jsx';
import { useLanguage } from '../../homepage/components/LanguageContext.js';
import SearchableDropdown from './SearchableSelect.jsx';
import SearchableSelect from './SearchableSelect.jsx';
import NavBarList from '../../docsearch/components/NavBarDoctorRegister.jsx';
import NavBarDoctorRegister from '../../docsearch/components/NavBarDoctorRegister.jsx';

export default function DoctorRegisterViewNew
  ({ 
    handleDoctorRegisterFormSubmit,
    handleCountryChange,
    handleStateChange,
    handleCityChange,
    templateData,
    professions,
    specialities,
    specialists,
    countries,
    states,
    cities,
    gender,
    setprofessionM,
    setSpecialityM,
    setLanguagesSpokenM,
    handleProfessionChange,
    handleSpecialityChange,
    handleSpecialistChange,
    handleGenderChange
  }) {
  const { langStrings } = useLanguage();
  const TT = langStrings.NEW_DOCTOR_REGISTER_PAGE;
  var ST = langStrings.DOCTOR_REGISTER_PAGE;
  var GI = ST.GENERAL_INFORMATION;
  var CD = ST.CONTACT_DETAILS;
  return (
    <>
    <NavBarDoctorRegister />
    <section class="min-h-screen bg-light">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-6 d-flex justify-content-center">
            <div class="card card-registration card-registration-2" >
              <div class="card-body p-0">
                <div class="row g-0">
                 <form class="form-horizontal bg-light" onSubmit={handleDoctorRegisterFormSubmit} method="post">
                  <div style={{backgroundColor:'#595959'}} class="col-12">
                    <div class="p-5">
                      <h3 style={{color:'white'}} class="fw-normal mb-5">{TT.label}</h3>
                      <div class="mb-4 pb-2">
                        <SearchableSelect 
                          name = "title"
                          arialabel = ""
                          defaultValue=  {GI.TITLE.label}
                          dataArAsObj = {GI.TITLE.values}
                          onChangeHandler={(e) => console.log('Selected Value:', e.target.value)}
                        />          
                      </div>
                      
                      <div class="row">
                        <div class="col-md-6 mb-0 pb-2">
                          <input type="text" id="firstname" name="firstname" className="form-control" placeholder={GI.FIRSTNAME.label}style={{backgroundColor:'white'}}/>
                        </div>
                        <div  class="col-md-6 mb-0 pb-2">
                          <input type="text" id="lastname" name="lastname" className="form-control" placeholder={GI.LASTNAME.label}style={{backgroundColor:'white'}}/>
                          {/* <MDBInput style={{backgroundColor:'white'}} label={GI.LASTNAME.label} name="lastname" type="text" staticLabel floating={false} /> */}
                          <br />
                        </div>
                      </div>
                      <div class="mb-4 pb-2">
                        <SearchableSelect
                          name = "SelectGender"
                          arialabel = ""
                          defaultValue=  {GI.GENDER.label}
                          dataArAsObj = {GI.GENDER.values}
                          onChangeHandler = {handleGenderChange}
                        />          
                      </div>
                      <div class="row">
                      <div class="mb-4 pb-2">
                      <input type="text" id="profileName" name="profileName" className="form-control" placeholder={GI.PROFILENAME.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white"> */}
                          {/* <MDBInput label={CD.STREET.label} name="street" type="text" />
                        </div> */}
                      </div>
                      </div>
                      <div class="mb-4 pb-2">
                        < SingleSelectComponent 
                            name = "profession"
                            defaultValue = {GI.PROFESSION.label}
                            dataArAsObj = {professions}
                            setFieldState = {setprofessionM}
                            onChangeHandler = {handleProfessionChange}
                        />
                      </div>
                      {/* {
                        specialities.length > 0 && ( */}
                        <div class="mb-4 pb-2">
                          < MultiSelectCompo2
                              id = "speciality"
                              name = "speciality"
                              label = {GI.SPECIALITY.label}
                              dataAr = {specialities}
                              setFieldState = {handleSpecialityChange}
                              disabled={specialities.length == 0}
                          />
                        </div>
                        {/* )
                      } */}
                      {/* {
                        specialists.length > 0 && ( */}
                        <div class="mb-4 pb-2">
                          < MultiSelectCompo2
                              id = "specialist"
                              name = "specialist"
                              label = {GI.SPECIALIST.label}
                              dataAr = {specialists}
                              setFieldState = {handleSpecialistChange}
                              gender={gender}
                              disabled={specialists.length == 0}
                          />
                        </div>
                        {/* )
                      } */}
                     
                      <div class="mb-4 pb-2">
                        < MultiSelectCompo2
                            id = {"languagesSpoken"}
                            name = {"languagesSpoken"}
                            label = {"Select your languages..."}
                            //defaultValue = "German?? "
                            dataAr = {templateData.language }
                            setFieldState = {setLanguagesSpokenM}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{backgroundColor:'#595959'}} class="col-12   text-white">
                    <div class="p-5">
                      <h3 style={{color:'white'}} class="fw-normal mb-5">{TT.label1}</h3>
                      <div class="mb-4 pb-2">
                        <SingleSelectComponent 
                          name = "practiceSize"
                          arialabel = ""
                          defaultValue=  {GI.PRACTICE_SIZE.label}
                          dataArAsObj = {GI.PRACTICE_SIZE.values}
                          onChangeHandler={(e) => console.log('Selected Value:', e.target.value)}
                        />          
                      </div>     
                      <div class="mb-4 pb-2">
                        <SingleSelectComponent
                          name = "country"
                          arialabel = ""
                          defaultValue=  {CD.COUNTRIES.label}
                          dataArAsObj = {countries}
                          onChangeHandler = {handleCountryChange}
                        />          
                      </div>    
                      <div class="mb-4 pb-2">
                        <SingleSelectComponent 
                          name = "state"
                          arialabel = ""
                          defaultValue=  {CD.STATES.label}
                          dataArAsObj = {states}
                          onChangeHandler = {handleStateChange}
                        />          
                      </div>       
                      <div class="mb-4 pb-2">
                        <SingleSelectComponent 
                          name = "city"
                          arialabel = ""
                          defaultValue=  {CD.CITIES.label}
                          dataArAsObj = {cities}
                          onChangeHandler = {handleCityChange}
                        />          
                      </div>
                      <div class="mb-4 pb-2">
                        {/* <div data-mdb-input-init class="form-outline form-white"> */}
                          <input type="text" id="address" name="address" className="form-control" placeholder={CD.ADDRESS.label} style={{backgroundColor:'white'}}/>
                          {/* <MDBInput label={CD.ADDRESS.label} name="address" type="text" /> */}
                        {/* </div> */}
                      </div>
                      <div class="mb-4 pb-2">
                      <input type="text" id="street" name="street" className="form-control" placeholder={CD.STREET.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white"> */}
                          {/* <MDBInput label={CD.STREET.label} name="street" type="text" />
                        </div> */}
                      </div>
                      <div class="mb-4 pb-2">
                        <input type="text" id="area" name="area" className="form-control" placeholder={CD.AREA.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white">
                          <MDBInput label={CD.AREA.label} name="area" type="text" />
                        </div> */}
                      </div>
                      <div class="mb-4 pb-2">
                      <input type="text" id="pincode" name="pincode" className="form-control" placeholder={CD.PINCODE.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white">
                          <MDBInput label={CD.PINCODE.label} name="pincode" type="text" />
                        </div> */}
                      </div>
                      <div class="mb-4 pb-2">
                      <input type="text" id="mail" name="mail" className="form-control" placeholder={CD.MAIL.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white">
                          <MDBInput label={CD.MAIL.label} name="mail" type="text" />
                        </div> */}
                      </div>
                      {/* isdCode */}
                      {/* <div class="mb-4 pb-2">
                        <div data-mdb-input-init class="form-outline form-white">
                          <SingleSelectComponent 
                          name = "isdCode"
                          arialabel = ""
                          defaultValue=  {CD.ISD_CODE.label}
                          dataArAsObj = {CD.ISD_CODE.values}
                          />
                        </div>
                      </div> */}
                      <div class="mb-4 pb-2">
                        {/* <div data-mdb-input-init class="form-outline form-white"> */}
                          <input type="text" id="website" name="website" className="form-control" placeholder={CD.WEBSITE.label} style={{backgroundColor:'white'}}/>
                          {/* <MDBInput label={CD.ADDRESS.label} name="address" type="text" /> */}
                        {/* </div> */}
                      </div>
                      <div class="mb-4 pb-2">
                      <input type="text" id="phone" name="phone" className="form-control" placeholder={CD.PHONE.label} style={{backgroundColor:'white'}}/>
                        {/* <div data-mdb-input-init class="form-outline form-white">
                          <MDBInput label={CD.PHONE.label} name="phone" type="text" />
                        </div> */}
                      </div>
                      <div class="form-check d-flex justify-content-start mb-4 pb-3">
                        <input class="form-check-input me-3" type="checkbox" value="" id="form2Example3c" />
                        <label class="form-check-label text-white" for="form2Example3">
                        {TT.label2} <a href="#!" class="text-white"><u>{TT.label3}</u></a> {TT.label4}
                        </label>
                      </div>
                      <button style={{backgroundColor:'#f35b2a',fontWeight:'bold',color:'white'}} type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn-lg"
                        data-mdb-ripple-color="dark">{TT.label5}</button>
                    </div>
                  </div>
                 </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );

}