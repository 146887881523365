import React, { useState, useRef, useEffect } from 'react';
import { Send, User, ChevronLeft } from 'lucide-react';

const HelpClient = () => {
  const [messages, setMessages] = useState([
    { id: 1, type: 'bot', text: 'Hi there! How can I help you today?', isCategory: false }
  ]);
  const [categories, setCategories] = useState([
    { id: 'account', text: 'Account Related Issues' },
    { id: 'search', text: 'Searching Doctors' },
    { id: 'appointment', text: 'Booking Appointment' },
    { id: 'other', text: 'Other Questions' }
  ]);
  const [userInput, setUserInput] = useState('');
  const [showCategories, setShowCategories] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const messagesEndRef = useRef(null);

  const categoryResponses = {
    'account': {
      topics: [
        { id: 'login', text: 'Login Problems' },
        { id: 'signup', text: 'Sign Up Issues' },
        { id: 'password', text: 'Password Reset' },
      ],
      responses: {
        'login': "To fix login issues, try clearing your browser cookies, ensure caps lock is off, and verify your email address is correct. If you're still having trouble, click 'Forgot Password'.",
        'signup': "If you're having problems signing up, make sure you're using a valid email format and a strong password. Check that all required fields are completed and try a different browser if issues persist.",
        'password': "To reset your password, click the 'Forgot Password' link on the login page. You'll receive an email with a secure link to create a new password. Check your spam folder if you don't see the email."
      }
    },
    'search': {
      topics: [
        { id: 'find', text: 'Finding Doctors' },
        { id: 'search', text: 'Search Not Working' },
        { id: 'broken', text: 'Broken Links' }
      ],
      responses: {
        'find': "To find products, use the search bar at the top of the page. You can also use filters to narrow down results.",
        'search': "If search isn't working, try refreshing the page or using more general keywords. Our search works best with simple terms rather than complex phrases.",
        'broken': "If you've encountered broken links, please let us know which page you were on and which link was broken. In the meantime, try navigating through the Home page"
      }
    },
    'appointment': {
      topics: [
        { id: 'schedule', text: 'Schedule an Appointment' },
        { id: 'reschedule', text: 'Reschedule Appointment' },
        { id: 'cancel', text: 'Cancel Appointment' }
      ],
      responses: {
        'schedule': "You can schedule an appointment by selecting your preferred date and time from our search page or from doctor's page by clicking on booking an appointment. Once confirmed, you'll receive a notification with the details.",
        'reschedule': "Need to reschedule? Contact our team support via mail or call us at they will assist you and will try to reschedule the appointment for you.",
        'cancel': "To cancel an appointment,Contact our team support via mail or call us at they will assist you to resolve the issue you are facing due to which you would like to cancel"
      }
    },

    'other': {
      topics: [
        { id: 'contact', text: 'Contact Support Team' },
        { id: 'feedback', text: 'Give Feedback' },
        { id: 'hours', text: 'Support Hours' }
      ],
      responses: {
        'contact': "To speak with our support team directly, please email  or call us at . We're here to help!",
        'feedback': "We value your feedback! Please share your thoughts about our website or services on our mail , and we'll use your input to improve.",
        'hours': "Our support team is available on ."
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const showLoadingIndicator = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleGoBack = () => {
    showLoadingIndicator();
    
    setTimeout(() => {
      const newBotMessage = { 
        id: messages.length + 1, 
        type: 'bot', 
        text: "Let's try a different category. What can I help you with?", 
        isCategory: false
      };
      
      setMessages([...messages, newBotMessage]);
      setShowCategories(true);
      setCurrentCategory(null);
    }, 1000);
  };

  const handleCategorySelect = (categoryId) => {
    showLoadingIndicator();
    setCurrentCategory(categoryId);
    
    setTimeout(() => {
      const newUserMessage = { 
        id: messages.length + 1, 
        type: 'user', 
        text: categories.find(cat => cat.id === categoryId).text,
        isCategory: false 
      };
      
      const botTopics = categoryResponses[categoryId].topics;
      const newBotMessage = { 
        id: messages.length + 2, 
        type: 'bot', 
        text: `What specifically about ${newUserMessage.text.toLowerCase()} can I help with?`, 
        isCategory: false,
        topics: botTopics,
        categoryId: categoryId
      };
      
      setMessages([...messages, newUserMessage, newBotMessage]);
      setShowCategories(false);
    }, 1000);
  };

  const handleTopicSelect = (categoryId, topicId) => {
    showLoadingIndicator();
    
    setTimeout(() => {
      const category = categoryResponses[categoryId];
      const topic = category.topics.find(t => t.id === topicId);
      const response = category.responses[topicId];
      
      const newUserMessage = { 
        id: messages.length + 1, 
        type: 'user', 
        text: topic.text,
        isCategory: false 
      };
      
      const newBotMessage = { 
        id: messages.length + 2, 
        type: 'bot', 
        text: response, 
        isCategory: false,
        showCategories: true
      };
      
      setMessages([...messages, newUserMessage, newBotMessage]);
      setShowCategories(true);
      setCurrentCategory(null);
    }, 1000);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const newUserMessage = { 
      id: messages.length + 1, 
      type: 'user', 
      text: userInput,
      isCategory: false 
    };
    
    setMessages([...messages, newUserMessage]);
    setUserInput('');
    showLoadingIndicator();
    
    setTimeout(() => {
      const newBotMessage = { 
        id: messages.length + 2, 
        type: 'bot', 
        text: "I understand you're asking about " + userInput + ". Please select a category so I can better assist you.", 
        isCategory: false
      };
      
      setMessages(prevMessages => [...prevMessages, newBotMessage]);
      setShowCategories(true);
      setCurrentCategory(null); 
    }, 1200);
  };

  const LoadingIndicator = () => (
    <div className="d-flex mb-3 justify-content-start">
      <div className="avatar me-2 align-self-end">
        <div className="rounded-circle bg-light p-1 d-flex align-items-center justify-content-center" style={{ width: '36px', height: '36px' }}>
          <img src='help-desk.svg' width={24} height={24} alt='bot-avatar' />
        </div>
      </div>
      <div className="message p-3 rounded-3 bg-light text-dark" style={{ maxWidth: '75%' }}>
        <div className="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="chat-container w-50 " style={{  maxWidth: '100%' }}>
        <div className="card border shadow rounded-3 ">
  
          <div className="card-header bg-primary text-white py-3">
            <div className="d-flex align-items-center">
              <div className="bg-white rounded-circle p-1 me-2">
                <img src='help-desk.svg' width={24} height={24} alt='help-icon' />
              </div>
              <h5 className="m-0">Help Assistant</h5>
            </div>
          </div>

          <div className="card-body p-3" style={{ height: '400px', overflowY: 'auto' }}>
            {messages.map((message) => (
              <div 
                key={message.id} 
                className={`d-flex mb-3 ${message.type === 'user' ? 'justify-content-end' : 'justify-content-start'}`}
              >
                {message.type === 'bot' && (
                  <div className="avatar me-2 align-self-end">
                    <div className="rounded-circle bg-light p-1 d-flex align-items-center justify-content-center" style={{ width: '36px', height: '36px' }}>
                      <img src='help-desk.svg' width={24} height={24} alt='bot-avatar' />
                    </div>
                  </div>
                )}
                
                <div 
                  className={`message p-3 rounded-3 ${
                    message.type === 'user' 
                      ? 'bg-primary text-white' 
                      : 'bg-light text-dark'
                  }`}
                  style={{ maxWidth: '75%' }}
                >
                  <div className="mb-1">
                    {message.text}
                  </div>
                  
                  {message.topics && (
                    <div className="d-flex flex-column gap-2 mt-3">
                      {message.topics.map(topic => (
                        <button 
                          key={topic.id} 
                          className="btn btn-sm btn-outline-primary text-start"
                          onClick={() => handleTopicSelect(message.categoryId, topic.id)}
                        >
                          {topic.text}
                        </button>
                      ))}

                      <button 
                        className="btn btn-sm btn-outline-secondary text-start mt-2"
                        onClick={handleGoBack}
                      >
                        <ChevronLeft size={16} className="me-1" />
                        Go back to categories
                      </button>
                    </div>
                  )}
                  
                  {message.showCategories && (
                    <div className="mt-2">
                      <p className="small text-muted mb-1">Need help with something else?</p>
                    </div>
                  )}
                </div>
                
                {message.type === 'user' && (
                  <div className="avatar ms-2 align-self-end">
                    <div className="rounded-circle bg-secondary p-1 d-flex align-items-center justify-content-center" style={{ width: '36px', height: '36px' }}>
                      <User size={20} color="white" />
                    </div>
                  </div>
                )}
              </div>
            ))}
            
            {isLoading && <LoadingIndicator />}
            
            <div ref={messagesEndRef} />
          </div>

          {showCategories && (
            <div className="border-top p-3 bg-light">
              <p className="small text-muted mb-2">Select a topic:</p>
              <div className="d-flex flex-wrap gap-2">
                {categories.map(category => (
                  <button 
                    key={category.id} 
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => handleCategorySelect(category.id)}
                  >
                    {category.text}
                  </button>
                ))}
              </div>
            </div>
          )}

          {!showCategories && currentCategory && (
            <div className="border-top p-3 bg-light">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="small text-muted mb-0">Select an option:</p>
                <button 
                  className="btn btn-sm btn-outline-secondary d-flex align-items-center"
                  onClick={handleGoBack}
                >
                  <ChevronLeft size={16} className="me-1" />
                  Back to categories
                </button>
              </div>
            </div>
          )}

          <div className="card-footer border-top p-2 bg-white" style={{overflow:'visible'}}>
            <form className="d-flex w-100" onSubmit={handleSendMessage}>
              <input 
                type="text" 
                className="form-control me-2" 
                placeholder="Type your question..." 
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
              <button type="submit" className="btn btn-primary d-flex align-items-center justify-content-center" style={{ width: '40px', height: '38px',position: 'relative' }}>
              <Send size={20} color="white" style={{ position: 'absolute' }} />
              </button>
            </form>
          </div>
        </div>
      </div>
      
      <style jsx>{`
        .typing-indicator {
          display: flex;
          align-items: center;
        }
        
        .typing-indicator span {
          height: 8px;
          width: 8px;
          margin: 0 2px;
          background-color: #6c757d;
          display: block;
          border-radius: 50%;
          opacity: 0.4;
        }
        
        .typing-indicator span:nth-of-type(1) {
          animation: bounce 1s infinite 0.1s;
        }
        
        .typing-indicator span:nth-of-type(2) {
          animation: bounce 1s infinite 0.2s;
        }
        
        .typing-indicator span:nth-of-type(3) {
          animation: bounce 1s infinite 0.3s;
        }
        
        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
        }
      `}</style>
    </div>
  );
};

export default HelpClient;