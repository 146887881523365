import React from 'react';
import { Star } from 'lucide-react';
import { useLanguage } from '../../homepage/components/LanguageContext';

const GoogleReviews = ({ rating = 0, totalReviews = 0, isAuthenticated = false, isOwner = false, onEdit, onReview }) => {
  const { language, langStrings } = useLanguage();
  const ST = langStrings.DOCPROFILEPAGE;

  const ratingContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const starsContainerStyle = {
    display: 'flex',
    marginLeft: '4px',
    alignItems: 'center'
  };

  const reviewCountStyle = {
    fontSize: '0.875rem',
    color: '#6b7280',
    marginLeft: '4px'
  };

  const buttonStyle = {
    padding: '8px 16px',
    backgroundColor: '#f35b2a',
    color: 'white',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: '8px',
    textAlign: 'center'
  };

  const imageContainerStyle = {
    width: '200px',
    height: 'auto',
    margin: '4px auto 4px auto'
  };

  const fullStars = Math.floor(rating);
  const hasPartialStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasPartialStar ? 1 : 0);
  const partialStarWidth = ((rating % 1) * 100).toFixed(0);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',gap:'4px', textAlign: 'center' }}>
      <div style={imageContainerStyle}>
        <img 
          src="/Google.webp" 
          alt="Google Reviews"
          style={{
            width: '80%',
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </div>

      <div style={ratingContainerStyle}>
        <div style={ratingContainerStyle}>
          <span style={{ fontSize: '1.125rem', fontWeight: 500 }}>{rating}</span>
          <div style={starsContainerStyle}>
            {[...Array(fullStars)].map((_, i) => (
              <Star 
                key={`full-${i}`} 
                style={{ 
                  width: '16px', 
                  height: '16px', 
                  fill: '#facc15', 
                  color: '#facc15',
                  flexShrink: 0
                }} 
              />
            ))}
            {hasPartialStar && (
              <div style={{ 
                position: 'relative', 
                width: '16px', 
                height: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0
              }}>
                <Star 
                  style={{ 
                    position: 'absolute',
                    width: '16px', 
                    height: '16px', 
                    color: '#facc15',
                  }} 
                />
                <div style={{ 
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  overflow: 'hidden', 
                  width: `${partialStarWidth}%`,
                  height: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Star 
                    style={{ 
                      width: '16px', 
                      height: '16px', 
                      fill: '#facc15', 
                      color: '#facc15',
                    }} 
                  />
                </div>
              </div>
            )}
            {[...Array(emptyStars)].map((_, i) => (
              <Star 
                key={`empty-${i}`} 
                style={{ 
                  width: '16px', 
                  height: '16px', 
                  color: '#facc15',
                  flexShrink: 0
                }} 
              />
            ))}
          </div>
          <span style={reviewCountStyle}>({totalReviews})</span>
        </div>
      </div>

      <div>
        {isAuthenticated && isOwner ? (
          <button 
            onClick={onEdit}
            style={buttonStyle}
            onMouseOver={e => e.target.style.backgroundColor = '#d94d23'}
            onMouseOut={e => e.target.style.backgroundColor = '#f35b2a'}
          >
            {ST.label1}
          </button>
        ) : (
          <button 
            onClick={onReview}
            style={buttonStyle}
            onMouseOver={e => e.target.style.backgroundColor = '#d94d23'}
            onMouseOut={e => e.target.style.backgroundColor = '#f35b2a'}
          >
            {ST.label2}
          </button>
        )}
      </div>
    </div>
  );
};

export default GoogleReviews;