import React from 'react'
import Footer from '../../homepage/components/Footer.jsx';
import NavBarList from '../../docsearch/components/NavBarList'
import { useLanguage } from '../../homepage/components/LanguageContext.js';
import { useOnlineStatus } from '../../../common_components/hooks/OnlineStatus.js';
import OfflineStatus from '../../../common_components/Offline/OfflineStatus.jsx';


const TermsOfService = () => {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.TERMSOFSERVICEPAGE;
  const isOnline = useOnlineStatus();
  if(!isOnline) {
    return <OfflineStatus />
  }
  return (
    <div>
    <NavBarList />
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="container p-5 rounded shadow bg-white text-dark" style={{ width: '80%', height: 'auto', minHeight: '60vh' }}>
        <h2 className="text-center mb-4">{ST.terms_of_service}</h2>
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default TermsOfService;