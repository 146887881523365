import AreYouDoc from "../features_new/homepage/components/AreYouDoc";

const englishStrings = {
  NEW_DOCTOR_REGISTER_PAGE:{
    label:'General Information',
    label1:'Contact Details',
    label2:'I do accept the',
    label3:'Terms and Conditions',
    label4:'of your site.',
    label5:'Register',
  },
  FALLBACKERRORPAGE:{
    Oops_Something_Went_Wrong:'Oops! Something Went Wrong',
    We_are_sorry:`We're sorry, but something unexpected happened. Please try again later.`,
    Try_Again:'Try Again'
  },
  DOCTORREGISTER:{
    title:`Please select a title`,
    first:`Please enter your first name`,
    last:`Please enter your last name`,
    profile:`Please enter your profile name`,
    profession:`Please select your profession`,
    speciality:`please select a speciality`,
    specialist:`Please select a specialist`,
    language: `Please select languages spoken`,
    practice:`Please enter your practice size`,
    country:`Please select your country`,
    state:`Please select your state`,
    city:`Please select your city`,
    address:`Please enter your address`,
    street:`Please enter your street`,
    area:`Please enter your area`,
    pincode:`Please enter your pincode`,
    email:`Please enter your email`,
    inValidEmail:`Please enter a valid email address`,
    phone:`Please enter your phone number`,
    welcome:`Welcome to Arztme`,
    wrong:`Something went wrong. Please try again later`,

  },
  OFFLINEPAGE:{
    Offline:`You're Offline`,
    Check:`Please check your internet connection and try again`,
    Again:`Try Again`
  },
  NOTFOUNDPAGE:{
    Page_Not_Found:'404,Page Not Found',
    Url:`We can't seem to find the page you're looking for.Please Check your Url.`,
    Home:`Return to Homepage`
  },
  DOCTOR_REGISTER_PAGE: {
    GENERAL_INFORMATION: {
      TITLE: {
        label: 'Title',
        values: {
          'Dr.': 'Dr.',
          'Mr.': 'Mr.',
          'Mrs.': 'Mrs.',
          'Miss.': 'Miss.',
          'Dr. med.': 'Dr. med.',
          'Dr. med. dent.': 'Dr. med. dent.',
          'Dr. med. vet.': 'Dr. med. vet.',
          'Dr. med. sci.': 'Dr. med. sci.',
          'Prof. Dr.': 'Prof. Dr.',
          'PD Dr.': 'PD Dr.',
          'Assoc. Prof. Dr.': 'Assoc. Prof. Dr.',
          'Dr. h.c.': 'Dr. h.c.',
          'Dr. mult.': 'Dr. mult.',
          'Prof. Dr. Dr.': 'Prof. Dr. Dr.',
          'Dr. med. habil.': 'Dr. med. habil.',
          'Dr. rer. nat.': 'Dr. rer. nat.',
          'Dr. rer. medic.': 'Dr. rer. medic.',
          'Dr. hc mult.': 'Dr. hc mult.',
          'Dr. Dr.': 'Dr. Dr.',
          'Prof. Dr. Dr. med. habil.': 'Prof. Dr. Dr. med. habil.'
        }
      },
      GENDER: {
        label: 'Select Gender',
        values:{
          'MALE': 'Male',
          'FEMALE': 'Female',

        }
      },
      FIRSTNAME:{
        label: 'First name'
      },
      LASTNAME: {
        label: 'Last name'
      },
      PROFILENAME:{
        label: 'Profile Name'
      },
      PROFESSION:{
        label: 'Select Your Profession',
        values: {
          "1": "Doctor",
          "2": "Physio",
          "3": "Hebama",
          "4": "Life Coach"
        }
      },
      SPECIALITY: {
        label: "Select Your Speciality",
      },
      SPECIALIST: {
        label: "Select Your Specialist"
      },
      LANGUAGES:{
        label: "Languages Spoken"
      },
      PRACTICE_SIZE:{
        label: "Enter Practice Size",
        values:{
          "1-5": "1-5",
          "6-10": "6-10",
          "11-20": "11-20",
          "20+": "20+"
        }
      }
    },
    CONTACT_DETAILS:{
      COUNTRIES:{
        label: "Select Country"
      },
      STATES:{
        label: "Select State"
      },
      CITIES:{
        label: "Select City"
      },
      ADDRESS:{
        label: 'Address'
      },
      STREET: {
        label: 'Street'
      },
      AREA: {
        label: 'Area'
      },
      PINCODE: {
        label: 'Pincode'
      },
      WEBSITE:{
        label: 'Website'
      },
      MAIL: {
        label: 'Your E-Mail for reservations'
      },
      ISD_CODE: {
        label: 'Select Isd code',
        values:{
          "+91": "+91",
          "+43": "+43"
        }
      },
      PHONE: {
        label: 'Phone Number'
      },
    }
  },
  HOMEPAGE:{
    NAV_BAR_SECTION:{
      SITELOGO:{
        label: 'Find your medical provider'
      },
      DOCTOR_OR_THERAPIST_BTN:{
        label: 'Are you a doctor or therapist?'
      },
      HELP:{
        label: 'Help'
      },
      LOGIN:{
        label: 'Login',
        label1: 'Logout',
        label2: 'Manage Profile',
        label3: 'Logged In',
      }
    },
    SEARCH_SECTION:{
      HEADER:{
        label: "Find your",
        label1: "GP/",
        label2: "Midwife/",
        label3: "Physio/",
        label4: "Paediatrician",
        label5: "Orthopaedist",
        label6: "Cardiologist",
        label7: "Gynaecologist",
        label8: "Dermatologist",
        label9:"Radiologist",
        label10:"Urologist",
        label11:"Neurologist",
        label12:"Nephrologist",
        label13:"ENT Doctor"
      },
      INPUT:{
        label: 'Searching For a Doctor or Therapist',
        label1: 'Location',
        label2: 'Search',
        label3:'Searching For a Doctor',
      },
      FILTER_CARDS:{
        label: 'Top Searched Specialties',
        label1: 'Life Coach',
        label2: 'Midwife',
        label3: 'Physiotherapist',
        label4: 'Dentist',
        label5: 'Gynecologist',
        label6: 'Pediatrician',
        label7: 'Physician',
        label8: 'Orthopedics',
        label9: 'Eye Specialist',
        label10: 'Plastic Surgery',
      },
      CITIES:{
        
        label: 'Find Providers by City',
        label1: 'Vienna',
        label6: 'Klagenfurt',
        label11: 'Wiener Neustadt',
        label16: 'Leonding',
        label2: 'Graz',
        label7: 'Villach',
        label12: 'Wolfsberg',
        label17: 'Klosterneuburg',
        label3: 'Linz',
        label8: 'Wels',
        label13: 'Steyr',
        label18: 'Baden bei Wien',
        label4: 'Salzburg',
        label9: 'Sankt Pölten',
        label14: 'Feldkirch',
        label19: 'Leoben',
        label5: 'Innsbruck',
        label10: 'Dornbirn',
        label15: 'Bregenz',
        label20: 'Traun',
        label21: 'Doctors',
        label22: 'Dentists',
        label23: 'Pediatric Dentists',
        label24: 'Pediatricians',
        label25: 'Dermatologists',
        label26: 'Psychiatrists',
        label27: 'Therapists',
        label28: 'Eye Doctors',
        label29: 'ENT Doctors',
        // label30: 'Urgent Care',
        label32: 'Hospitals',
        label33: 'OB-GYNs'
      },
      CITIES1:{
        
        label: 'Find Providers by City',
        label1: 'Vienna',
        label2: 'Klagenfurt',
        label3: 'Wiener Neustadt',
        label4: 'Leonding',
        label5: 'Graz',
        label6: 'Villach',
        label7: 'Wolfsberg',
        label8: 'Klosterneuburg',
        label9: 'Linz',
        label10: 'Wels',
        label11: 'Steyr',
        label12: 'Baden bei Wien',
        label13: 'Salzburg',
        label14: 'Sankt Pölten',
        label15: 'Feldkirch',
        label16: 'Leoben',
        label17: 'Innsbruck',
        label18: 'Dornbirn',
        label19: 'Bregenz',
        label20: 'Traun',
        label21: 'Doctors',
        label22: 'Dentists',
        label23: 'Pediatric Dentists',
        label24: 'Pediatricians',
        label25: 'Dermatologists',
        label26: 'Psychiatrists',
        label27: 'Therapists',
        label28: 'Eye Doctors',
        label29: 'ENT Doctors',
        //label30: 'Urgent Care',
        label32: 'Hospitals',
        label33: 'OB-GYNs'
      }
    },
    FOOTER_SECTION:{
        LINKS:{
          label: 'Home',
          label1: 'About Us',
          label2: 'Blog',
          label3: 'Contact us',
          label4: 'General Terms & Conditions',
          label5: 'Terms of use of Website',
          label6: 'Personal data protection policy',
          label7: 'Cookie Policy',
          label8: 'Cookie management',
          label9: 'Legal Notice',
          label10: 'Help',
          label11: 'Impressum',
          label12: 'List your practice on Artzme',
          label13: 'Marketing for your Practice',
          label14: 'Careers',
          label15: 'Deutsch',
          label16: '© 2025 Arztme e.U. all rights reserved'
        }
    },
    HAMBURG:{
      label:'Welcome to',
      label1:'Interested in ',
      label2:'Listing your Practice?'
    },
    
  },
  AreYouDoc:{
    HEADERS:{
      label: 'The easiest way to grow your practice.',
      label1: 'Arztme helps you bring in more new patients and keep them coming back – while saving your practice valuable time.',
      label2: 'Are you a provider interested in reaching new patients?',
      label3: 'Reach patients in your area looking for a new provider',
      label4: 'Fill last-minute openings in your schedule',
      label5: 'Strengthen your online reputation with verified reviews',
      label6: 'List Your Practice on Arztme',
      login:`Please login to continue`,
      doctor:`You are already registered as a doctor`,
    }
  },
  SEARCHFIL:{
    NAV_BAR_SECTION:{
      SITELOGO:{
        label: 'Find your medical provider'
      },
      DOCTOR_OR_THERAPIST_BTN:{
        label: 'Are you a doctor or therapist?'
      },
      HELP:{
        label: 'Help'
      },
      LOGIN:{
        label: 'Login',
        label1:'Logout'
      }
    },
    PAGES:{
      prev:'PREV',
      next:'NEXT'
    },
    INPUT:{
      label: 'Searching For a Doctor or Therapist',
      label1: 'Location',
      label2: 'Search',
      label4: "Searching For a Doctor"
    },
    FILTERS:{
      label:'Language',
      label1:'Accepting New Patient',
      label2:'Insurance',
      label3:'Verified'
    },
    FILTERSINFO:{
      label:'Yes',
      label1:'No',
      label2:'Legally insured',
      label3:'Privately insured and self-paying',
      label4:'English',
      label5:'German',
      label6:'French',
      label7:'Italian',
      label8:'Croatian',
      label9:'No'
    },
    IMAGECARD:{
      label:'Admission of new patients',
      label1:'Average waiting time for an appointment',
      label2:'Practice Modernization Rating',
      label3:'Google review',
      label4:'Monday',
      label5:'Tuesday',
      label6:'Wednesday',
      label7:'Thursday',
      label8:'Friday',
      label9:'Saturday',
      label10:'Book an appointment',
      label11:'Opening Times',
      label12:'Days',
      label13:'Your response is recorded',
      label14:'Oops! Something went wrong'
    },
    NOMATCHES:{
      label:'Sorry, no matches were found',
      label1:'Remember to check your Spelling and try again.',
      label2:'Try using different keywords.'
    },
    STARMODAL:{
      label:'Sought after by many healthcare providers, but granted only to a select few who truly excel.',
      label1:'High-quality care',
      label2:'Outstanding support',
      label3:'Exceptional healthcare provider',
    }
  },
  FORGOTPAGE:{
    label:'Forgot Password',
    label1:'Enter your Email',
    label2:'Send Reset Link',
    label3:'A password reset link has been sent to your email.',
    label4:'Something went wrong Please try again later',
    IncorrectEmail:'Please Check your Email Id',
  },
  CONTACTUS:{
    label:`Let's get in touch`,
    label1:'We Provide Excellent Service at Arztme',
    label2:'Connect with us :',
    label3:'Contact us',
    label4:'Name',
    label5:'Email',
    label6:'Phone',
    label7:'Your Query',
  },
  LOGINPAGE:{
    label:`Login`,
    label1:'Your Email',
    label2:'Password',
    label3:'Login',
    label4:`Don't have an account`,
    label5:'Sign up',
    label6:'Forgot Password?',
    LoggedIn:'You are now logged in',
    InvalidCredentials:'Please Check your credentials',
    Trying:'Trying to Login',
    ErrorOccured:'Something went wrong.Please try again'
  },
  SIGNUPPAGE:{
    label:`Create an account`,
    label1:'Your Name',
    label2:'Your Email',
    label3:'Password',
    label4:`Repeat your password`,
    label5:'I agree to all statements in',
    label6:'Terms of service',
    label7:'Register',
    label8:'already have an account?',
    label9:'Login here'
  },
  ABOUTUSPAGE:{
    label:'About Arztme',
    label1:'Thousands of Providers.',
    label2:'One Platform.',
    label3:'Arztme is the quickest, easiest way to discover doctors and therapists in your area',
    label4:'Book a Consultation',
  },
  IMPRESSIUMPAGE:{
    label:'Impressium'
  },
  TERMSOFSERVICEPAGE:{
    terms_of_service:'Terms of Service'
  },
  CAREERSPAGE:{
    label:'Careers',
    label1:'Join Our Team',
    label2:`At Arztme™, we’re building something new, bold, and exciting. As we grow, we’re looking for passionate, innovative, and driven individuals who are ready to make an impact. Whether you’re a designer, developer, marketer, or strategist, if you're looking for a place where you can contribute, learn, and grow, we’d love to hear from you.`,
    label3:`We’re a fast-paced startup that thrives on creativity, collaboration, and the drive to solve real-world problems. We believe in empowering our team to take ownership, try new things, and push the boundaries of what’s possible.`,
    label4:`Why Work with Us?`,
    label5:'Flexible Environment:',
    label6:'We value work-life balance and offer flexibility in where and when you work.',
    label7:'Growth Opportunities:',
    label8:'As a growing startup, we provide plenty of room for you to grow your skills, take on new challenges, and advance your career.',
    label9:'Collaborative Culture:',
    label10:`You'll work closely with a passionate, supportive team of diverse thinkers who are dedicated to making an impact.`,
    label11:'Make an Impact:',
    label12:`Your work will matter from day one. At Arztme™, we value each person’s contributions and believe everyone plays a vital role in our success.`,
    label13:'Current Openings',
    label14:`We're accepting applications on a rolling basis, so if you're excited about the work we do but don't see a position that perfectly fits right now, feel free to reach out! We’re always on the lookout for talented people to join our team.`,
    label15:'How to Apply:',
    label16:`Please submit your resume and a brief cover letter explaining why you're excited about the opportunity to join us. Be sure to include a portfolio or samples of your work (if relevant). We’ll review applications as they come in and will reach out to qualified candidates for an interview.`,
    label17:'Ready to make an impact? Apply today!',
    label18:'',
    label19:'',
    label20:'',
  },
  MARKETTINGPAGE:{
    label:'Marketting Your Practice',
    label1:'Thousands of Providers.',
    label2:'One Platform.',
    label3:'Arztme is the quickest, easiest way to discover doctors and therapists in your area',
    label4:'Book a Consultation',
  },
  RESETPASSWORDPAGE:{
    label:`Create New Password`,
    label1:'New Password',
    label2:'Confirm New Password',
    label3:'Reset Password',
    label4:'Password Changed Successfully',
    label5:'Oops Something Went Wrong'
  },
  DOCPROFILEPAGE:{
    label:'Google Rating',
    label1:'Edit Your Profile',
    label2:'Book Appointment',
    label3:'About Me',
    label4:'Show Map',
    label5:'Insurance',
    label6:'Timings',
    label7:'Graduation',
    label8:'Certificates',
    label9:'Photos and Videos',
    label10:'People in My Network',
    label11:'Arztme Reviews Summary',
    label12:'Write A Review',
    label13:'Please Login First to Write A Review',
    label14:'reviews',
    label15:'See More',
    label16:'Edit Your Review',
    label17:'Post Your Review',
    label18:'Your Review Topic',
    label19:'Your Review Content',
    label20:'',
  },
  BOOKINGMODAL:{
    label:'Book an appointment',
    label1:`I understand you want to book online appointments with your preferred doctor, but they aren’t available through GoArzt. We’re here to help! If you’d like, we can assist you in scheduling an appointment at their office or with another nearby provider.`,
    label2:'Please answer the questions below so we can find the best option for you',
    label3:'First and Last Name',
    label4:'What time of the day fits you best?',
    label5:'Any specific days or times to avoid',
    label6:'Is this appointment urgent?',
    label7:'What health insurance do you have?',
    label8:'Insurance Number',
    label9:'Anything else we should know? (e.g. language preferences)(Optional)',
    label10:'Your Phone No.',
    label11:'Email address',
    label12:'You agree with our terms and conditions',
    label13:'Submit',
    label14:'Morning',
    label15:'Afternoon',
    label16:'Evening',
    label17:'Yes',
    label18:'No,can wait a week or more',
    label19:'Public',
    label20:'Private or Self-pay',
    label21:'Insurance Number',
    label22:'Phone Number',
    label23:'Enter your email',
    label24:'Enter your name',
    label25:'Specific days/times',
    label26:'Full name is required.',
    label27:'Please select a preferred time slot.',
    label28:'Please specify preferred days/times to avoid.',
    label29:'Please indicate if the appointment is urgent.',
    label30:'Please select an insurance type.',
    label31:'Insurance number is required.',
    label32:'Please enter a valid 10-digit phone number.',
    label33:'Please enter a valid email address.',
    label34:'You must agree to the terms and conditions.',
    label35:'',
    post:`Posting your review`,
    sucess:`review posted successfully`,
    wrong:`Oops something went Wrong please Try again later`
  },
  USERREGISTERTOAST:{
    welcome:`Welcome to Arztme`,
    wrong:`Oops Something Went Wrong`
  },
  DOCEDIT:{
    SIDEBAR:{
      label:'Basic Info',
      label1:'Contact Info',
      label2:'About Info',
      label3:'Insurance Info',
      label4:'Education Info',
      label5:'Opening Times',
      label6:'BG-Images',
      label7:'Maps',
    },
    BASICINFO:{
      label:'Select Your Title',
      label1:'First Name',
      label2:'Last Name',
      label3:'Profession',
      label4:'Speciality',
      label5:'Language',
      label6:'Save Changes',
      ProfileName:'Profile Name',
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme'
    },
    CONTACTINFO:{
      label:'House Number',
      label1:'Street',
      label2:'Area',
      label3:'Country',
      label4:'State',
      label5:'City',
      label6:'PIN Code',
      label7:'Email Id',
      label8:'Isd Code',
      label9:'Phone',
      label10:'Save Changes',
      website:'Website',
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme',
      
    },
    ABOUTINFO:{
      label:'About Me',
      label1:'New Patient Acceptance',
      label2:'Average Waiting Time For Appointment (in Days)',
      label3:'Save Changes',
      label4:'About Me German',
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme',
      TranslationApproval:'Please approve the German translation before submitting.'
    },
    MAPINFO:{
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme',
    },
    BACKGROUNDIMAGES:{
      IMAGEUPLOADING:'Image is Uploading',
      IMAGEUPLOADED:'Image Uploaded Successfully',
      IMAGEUPLOADINGFAILED:'Image Uploading Failed',
      IMAGEDELETING:'Image is being Removed',
      IMAGEDELTED:'Image Deleted Successfully',
      IMAGEDELETINGFAILED:'Image Deletion Failed',
      UPLOAD:'Upload Image',
      UPLOADING:'Uploading',
      CANCEL:'Cancel',
      BACKGROUNDIMAGE:'Background Images'
    },
    INSURANCEINFO:{
      label:'Insurance Companies',
      label1:'Public Insurance',
      label2:'Save Changes',
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme'
    },
    EDUCATIONINFO:{
      label:'Education',
      label1:'Certificates',
      label2:'Save Changes',
      UpdateInProccess:'Details are being updated',
      DetailsUpdated:'Your Details are Updated',
      FailedToUpdate:'Failed To Update Details.Please Contact Arztme'
    },
    COOKIECONSENT:{
      label:'Manage Your Cookie Preferences',
      label1:'We respect the privacy of our users. Your data, your choices.',
      label2:'The Following are necessary cookies and required by the webiste to function properly.These cookies cannot be denied',
      label3:'Do you Consent to accept the Language Cookies?',
      label4:'Do you consent to accept the location Cookies?These Cookies Store your Location to provide smooth search experience',
      label5:'Save Preferences',
      CookiesConsent:'Cookies Consent',
      CookiesDescription:'This website uses cookies to help you have a superior and more relevant browsing experience.',
      Accept:'Accept',
      Decline:'Decline'
    },
    PROFILEHEADER:{
      label:'Image uploaded successfully',
      label1:'Oops Something Went Wrong'
    },
  },
  NAVBAR:{
    listing:'Listing',
    login:'Login',
    logout:'Logout',
    signup:'SignUp'
  }
}

export { englishStrings }; 