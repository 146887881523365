import { useState, useEffect } from 'react';
import utils from '../../../utils.js';
import { useNavigate } from 'react-router-dom';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import DoctorRegisterViewNew from './DoctorRegisterViewNew.jsx';
import { toast } from 'sonner';
import { useLanguage } from '../../homepage/components/LanguageContext.js';

var _ = require('lodash');

export default function DoctorRegisterNew() {
    
  
  const [templateData, setTemplateData] = useState(utils.getEmptyTemplateData());
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [professionM, setprofessionM] = useState([]);
  const [specialityM, setSpecialityM] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState([]);
  const [languagesSpokenM, setLanguagesSpokenM] = useState([]);
  const [gender, setGender] = useState("MALE");
  const [professions, setProfessions] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [specialists, setSpecialists] = useState([]);
  const [website,setWebsite] = useState("");
  const signIn = useSignIn();
  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL
  const doctorRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_DOCTOR_REGISTRATION_REL_URL;
  const {language,langStrings} = useLanguage();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const DT = langStrings.DOCTORREGISTER;
  const fetchTemplateApiData = async (url) => {
    const data = await callTemplateApi(url);
    setCountries(data.countryData);
    setProfessions(data.professionTieredData)
    setTemplateData(data);    
  }

  const callTemplateApi = async (url) => {
    const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
    const token = authUser()?.authToken ||  ''
    const response = await fetch(url, {
      headers: {
        'X-LOCALE': `${LanguageLocale}`,
        'X-AUTHTOKEN': token
      },
    });
    const body = await response.json();
    return body;
  }
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let countryIdx = countries.findIndex(country => country.id == e.target.value);
    setStates(countries[countryIdx].states);
    setCities("");
    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    let stateIdx = states.findIndex(state => state.id == e.target.value);
    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleProfessionChange = (selectedProfession) => {
    console.log('it came here', selectedProfession.target.value);
    const professionId = selectedProfession.target.value;
    setprofessionM(professionId);
    console.log("SID set profession as " + professionId);
    //setprofessionM(selectedProfession);
    const selectedProf = professions.find((prof) =>{
      console.log('profession',prof)
      return prof.id == professionId
    } );
    console.log('processing profession...',selectedProf);
    if (selectedProf) {
      const aggregatedSpecialities = selectedProf.specialities || [];
      console.log('Specialities available: ', aggregatedSpecialities);
  
      setSpecialities(aggregatedSpecialities);
    } else {
      console.log('No specialities found for the selected profession.');
      setSpecialities([]);
    }
    setSpecialityM("");
    setSelectedSpecialist("");
  };
  

  const handleSpecialityChange = (selectedSpecialities) => {
    setSpecialityM(selectedSpecialities);
    if (selectedSpecialities && selectedSpecialities.length > 0) {
      const aggregatedSpecialists = selectedSpecialities
        .map((selectedSpec) => {
          const speciality = specialities.find((spec) => spec.id === selectedSpec.value);
          return speciality?.specialists || [];
        })
        .flat();
      setSpecialists(aggregatedSpecialists);
    } else {
      setSpecialists([]);
    }
    setSelectedSpecialist("");
  };

  const handleSpecialistChange = (e) =>{
    setSelectedSpecialist(e);
  }
  
  const handleGenderChange = (e) =>{
    setGender(e.target.value);
  }

  async function handleDoctorRegisterFormSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    const validationChecks = [
        {
            condition: !formJson.title,
            message: DT.title
        },
        {
            condition: !formJson.firstname?.trim(),
            message: DT.first
        },
        {
            condition: !formJson.lastname?.trim(),
            message: DT.last
        },
        {
            condition: !formJson.profileName?.trim(),
            message: DT.profile
        },
        {
            condition: !formJson.profession && !professionM,
            message: DT.profession
        },
        {
            condition: specialities.length > 0 && (!specialityM || (Array.isArray(specialityM) && specialityM.length === 0)),
            message: DT.speciality
        },
        {
            condition: specialists.length > 0 && (!selectedSpecialist || (Array.isArray(selectedSpecialist) && selectedSpecialist.length === 0)),
            message: DT.specialist
        },
        {
            condition: !languagesSpokenM || (Array.isArray(languagesSpokenM) && languagesSpokenM.length === 0),
            message: DT.language
        },
        
        {
            condition: !formJson.practiceSize?.trim(),
            message: DT.practice
        },
        {
            condition: !formJson.country,
            message: DT.country
        },
        {
            condition: !formJson.state,
            message: DT.state
        },
        {
            condition: !formJson.city,
            message: DT.city
        },
        {
            condition: !formJson.address?.trim(),
            message: DT.address
        },
        {
            condition: !formJson.street?.trim(),
            message: DT.street
        },
        {
            condition: !formJson.area?.trim(),
            message: DT.area
        },
        {
            condition: !formJson.pincode?.trim(),
            message: DT.pincode
        },
        {
            condition: !formJson.mail?.trim(),
            message: DT.email
        },
        {
            condition: formJson.mail?.trim() && !isValidEmail(formJson.mail),
            message: DT.inValidEmail
        },
        {
            condition: !formJson.phone?.trim(),
            message: DT.phone
        },
       
    ];

   
    function isValidEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    

    
    for (const check of validationChecks) {
        if (check.condition) {
            toast.warning(check.message, {
                position: 'top-center',
                duration: 3000,
                style: {
                    fontSize: '15px',
                    padding: '10px 20px',
                    minWidth: '200px',
                }
            });
            return;
        }
    }


    let payloadObj = payloadFromFormData(formJson);
    let resp = await callRegisterHandler(doctorRegistrationUrl, payloadObj);
    
    if (resp.success) {
        let doctorMade = await getRefreshToken();
        if(doctorMade) {
            toast.success(DT.welcome, {
                position: 'top-center',
                duration: 3000,
                style: {
                    fontSize: '15px',
                    padding: '10px 20px',
                    minWidth: '200px',
                }
            });
            navigate('/');
        } else {
            toast.warning(DT.wrong, {
                position: 'top-center',
                duration: 3000,
                style: {
                    fontSize: '15px',
                    padding: '10px 20px',
                    minWidth: '200px',
                }
            });
        }
    } else {
        toast.warning(DT.wrong, {
            position: 'top-center',
            duration: 3000,
            style: {
                fontSize: '15px',
                padding: '10px 20px',
                minWidth: '200px',
            }
        });
    }
}

  async function getRefreshToken(){
    const token = authUser()?.authToken;
    const refreshUrl  = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER + process.env.REACT_APP_REFRESH_TOKEN;
    console.log(`Refresh token URL`, refreshUrl);
    try {
      const response = await fetch(refreshUrl,{
        method: 'POST',
        headers:{
          'Content-Type': 'application/json',
          'X-AUTHTOKEN': `${token}`
        }
      })
      if(!response.ok){
        toast.warning(DT.wrong, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
      }
      const data = await response.json();
      if(signIn({
        token: data.authToken,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState:{
        ...data, 
        userId: data.userDto.id, 
        isUser: data.isUser,
        isDoctor: data.isDoctor,
        doctorTitle: data?.doctorDto?.title || "Dr. ",
        doctorId: data?.doctorDto?.id || -1,
        userFirstName: data.userDto.firstname,
        userLastName: data.userDto.lastname
        }  
      })){
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  }

  function getStringFromObj(arObj){
    let values = [];
    // console.log(`Bhai idhar dekh`,arObj);
    if(arObj && Array.isArray(arObj)){
      arObj.forEach(function(each){
        values.push(each.value);
      })
      return values.join(',');
    }
    return '';
  }

  function getArrayFromObj(arObj){
    let values = [];
    // console.log(`Bhai idhar dekh`,arObj);
    if(arObj && Array.isArray(arObj)){
      arObj.forEach(function(each){
        values.push(each.value);
      })
    }
    return values;
  }

  function payloadFromFormData(form){
    console.log(`gender is `, form.SelectGender)
    let requestBody = {
      userId: authUser().userDto.id,
      title: form.title,
      gender: form.SelectGender,
      firstname: form.firstname,
      lastname: form.lastname,
      profileName: form.profileName,
      profession: [professionM], //getArrayFromObj(professionM),
      speciality: form.speciality || getArrayFromObj(specialityM),
      specialist: form.specialist || getArrayFromObj(selectedSpecialist),
      languagesSpoken: form.languagesSpoken || getArrayFromObj(languagesSpokenM),
      centers:[]
    };
    let center = {
      website: form.website,
      practiceSize: form.practiceSize,
      countryId: form.country,
      stateId : form.state,
      cityId: form.city,
      address: form.address,
      street: form.street,
      area: form.area,
      pincode: form.pincode,
      mail: form.mail,
      isdCode: "+43",
      phone: form.phone,
    }
    requestBody.centers.push(center);
    return requestBody;
  }

  async function callRegisterHandler(url, body) {
    let resp = await callRegisterApi(url, body);
    if (resp && resp.status === 200)
      return { success: true };
    else
      return { success: false };
  }

  const callRegisterApi = async (url, body) => {
    const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
    const token = authUser()?.authToken ||  ''
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-LOCALE': `${LanguageLocale}`,
        'X-AUTHTOKEN': token
      },
    });
    let resp = await response;
    return resp;
  }
    return (
        <div>
            <DoctorRegisterViewNew 
                handleDoctorRegisterFormSubmit = {handleDoctorRegisterFormSubmit}
                handleCountryChange = {handleCountryChange}
                handleStateChange = {handleStateChange}
                handleCityChange = {handleCityChange}
                templateData = {templateData}
                professions={professions}
                specialities={specialities}
                specialists={specialists}
                countries = {countries}
                states = {states}
                cities = {cities}
                gender = {gender}
                setprofessionM = {setprofessionM}
                setSpecialityM = {setSpecialityM}
                setLanguagesSpokenM = {setLanguagesSpokenM}
                handleProfessionChange = {handleProfessionChange}
                handleSpecialityChange={handleSpecialityChange}
                handleSpecialistChange={handleSpecialistChange}
                handleGenderChange={handleGenderChange}

            />
        </div>
    )
}