import './UserRegisterViewNew.css';
import { MDBInput } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Footer from '../../homepage/components/Footer';
import NavbarForgot from '../../homepage/components/NavbarForgot';
import { useLanguage } from '../../homepage/components/LanguageContext';
import { useLoader } from '../../../common_components/LoaderContext';
import { useNavigate } from 'react-router-dom';

export default function UserRegisterViewNew({ handleUserRegisterFormSubmit }) {
  const {setIsLoading} = useLoader();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: '',
    email: '',
    password: '',
    passwordconfirm: '',
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.firstname.trim()) formErrors.firstname = 'First name is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email address is invalid';
    }
    if (!formData.password) {
      formErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      formErrors.password = 'Password must be at least 6 characters';
    }
    if (formData.passwordconfirm !== formData.password) {
      formErrors.passwordconfirm = 'Passwords do not match';
    }
    if (!formData.agree) {
      formErrors.agree = 'You must agree to the terms and conditions';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleUserRegisterFormSubmit(e);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1((prev) => !prev);
  };
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.SIGNUPPAGE;

  const handleLoginNaviagtion = ()=>{
    setIsLoading(true);
    navigate('/user-login');
  }
  const TakeToTermsOfService = ()=>{
    navigate('/terms-of-service')
  }
  return (
    <div>
      <NavbarForgot />
      <section className="vh-100 bg-image user-register-view">
        <div className="mask d-flex align-items-center h-100 bg-light">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className="card user-register-card">
                  <div className="card-body p-5">
                    <h2 className="text-center mb-5">{ST.label}</h2>
                    <div>
                      <form id="user-registration-form" onSubmit={handleSubmit} method="post">
                        <MDBInput
                          label={ST.label1}
                          name="firstname"
                          type="text"
                          value={formData.firstname}
                          onChange={handleChange}
                        />
                        {errors.firstname && <p style={{ color: 'red' }}>{errors.firstname}</p>}
                        <br />

                        <MDBInput
                          label={ST.label2}
                          name="email"
                          type="text"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                        <br />

                        <div className="position-relative">
                          <MDBInput
                            label={ST.label3}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                          >
                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                          </span>
                        </div>
                        {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                        <br />

                        <div className="position-relative">
                          <MDBInput
                            label={ST.label4}
                            name="passwordconfirm"
                            type={showPassword1 ? 'text' : 'password'}
                            value={formData.passwordconfirm}
                            onChange={handleChange}
                          />
                          <span
                            onClick={togglePasswordVisibility1}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                          >
                            <FontAwesomeIcon icon={showPassword1? faEye : faEyeSlash} />
                          </span>
                        </div>
                        {errors.passwordconfirm && <p style={{ color: 'red' }}>{errors.passwordconfirm}</p>}
                        <br />

                        <div className="form-check d-flex justify-content-start mb-4">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            name="agree"
                            checked={formData.agree}
                            onChange={handleChange}
                            id="form2Example3cg"
                          />
                          <label className="form-check-label" htmlFor="form2Example3cg">
                            {ST.label5}{' '}<a  onClick={TakeToTermsOfService} className="text-body"><u>{ST.label6}</u></a>
                          </label>
                        </div>
                        {errors.agree && <p style={{ color: 'red' }}>{errors.agree}</p>}

                        <div className="d-flex justify-content-center">
                          <button
                            style={{
                              backgroundColor: '#f35b2a',
                              fontWeight: 'bold',
                              color: 'white',
                              boxShadow: 'none'
                            }}
                            type="submit"
                            className="btn btn-success btn-block btn-lg"
                          >
                            {ST.label7}
                          </button>
                        </div>

                        <p className="text-center text-muted mt-4 mb-0">
                          {ST.label8}<span onClick={handleLoginNaviagtion} style={{cursor:'pointer'}} className="fw-bold text-body">{' '}{ST.label9}</span>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
